import React from 'react';

import { Tabs, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import deliveryMan from 'assets/images/delivery.png';
import discount1 from 'assets/images/discount1.png';
import InfoIcon from 'assets/images/infoIcon.jpg';
import stopwatch from 'assets/images/stopwatch1.png';
import { DiscountTooltip, DeliveryFeeTooltip } from 'components';
import { toggleDeliveryMode } from 'store/cart/cart.action.creator';
import { selectDeliveryMode } from 'store/cart/cart.selectors';
import { EDeliveryMode } from 'store/cart/cart.types';
import {
  selectCurrentShop,
  selectMenuTime,
  selectShopCurrencySymbol
} from 'store/shop/shop.selectors';
import './Header.css';

export const Header = () => {
  const currentShop = useSelector(selectCurrentShop);
  const menuTime = useSelector(selectMenuTime);
  const currencySymbol = useSelector(selectShopCurrencySymbol);
  const deliveryMode = useSelector(selectDeliveryMode);

  const dispatch = useDispatch();

  let discount = '';
  const fixedDiscount = currentShop?.store_details.store_discount_amount;
  const percentagedDiscount = currentShop?.store_details.store_discount_percent;

  if (fixedDiscount) {
    discount = Number(fixedDiscount) === 0 ? 'No Discount' : `${currencySymbol}${fixedDiscount}`;
  } else if (percentagedDiscount) {
    discount = `${percentagedDiscount}%`;
  }

  const minAmountDelivery = Number(currentShop?.store_details?.store_min_amt_dlvry);

  return (
    <div className='menuWrapper'>
      <div className='menuBanner'>
        <img
          src={currentShop?.store_details.store_image_url}
          alt='banner'
        />
      </div>

      <div className='deliveryMode'>
        <h2>{currentShop?.store_details.store_name}</h2>

        <div className='modeTab'>
          <Tabs
            defaultActiveKey='delivery'
            id='uncontrolled-tab-example'
            activeKey={deliveryMode}
            onSelect={(k) => {
              if (k) dispatch(toggleDeliveryMode(k as EDeliveryMode));
            }}
          >
            <Tab
              eventKey={EDeliveryMode.DELIVERY}
              title={EDeliveryMode.DELIVERY ==='RESTAURANT_DELIVERY'?'DELIVERY':EDeliveryMode.DELIVERY}
            >
              <div className='deliveryTime'>
                <div className='timeMode'>
                  <img
                    src={stopwatch}
                    alt='time'
                  />

                  <p>
                    {Number(
                      currentShop?.store_details.store_prep_time_in_mins
                    ) +
                      (currentShop?.store_details.store_delivery_time_in_mins
                        ? Number(
                          currentShop.store_details
                            .store_delivery_time_in_mins
                        )
                        : 0)}{' '}
                    mins
                  </p>
                </div>

                <div className='timeMode'>
                  <div>

                    <img
                      src={deliveryMan}
                      alt='shipping'
                    />

                    <p>
                      {currencySymbol}

                      {currentShop?.store_details.store_delivery_fee}

                      {minAmountDelivery > 0 && (
                        <DeliveryFeeTooltip placement='bottom'>
                          <img
                            src={InfoIcon}
                            alt='info'
                            width={10}
                            style={{ marginLeft: 3, }}
                          />
                        </DeliveryFeeTooltip>
                      )}
                    </p>
                  </div>
                </div>

                <div className='timeMode'>
                  <div>
                    <img
                      src={discount1}
                      alt='discount'
                    />

                    <p>
                      {discount}

                      {(Number(fixedDiscount) > 0 || percentagedDiscount) && (
                        <DiscountTooltip>
                          <img
                            src={InfoIcon}
                            alt='info'
                            width={8}
                            style={{ marginLeft: 3, }}
                          />
                        </DiscountTooltip>
                      )}

                      {/* <span style={{ color: 'red', }}>*</span> */}
                    </p>
                  </div>
                </div>
              </div>
            </Tab>

            <Tab
              eventKey={EDeliveryMode.PICKUP}
              title={EDeliveryMode.PICKUP}
            >
              <div className='deliveryTime'>
                <div className='timeMode'>
                  <img
                    src={stopwatch}
                    alt='time'
                  />

                  <p>
                    {currentShop?.store_details.store_prep_time_in_mins} mins
                  </p>
                </div>

                <div className='timeMode'>
                  <DiscountTooltip>
                    <div>
                      <img
                        src={discount1}
                        alt='discount'
                      />

                      <p>
                        {discount}

                        <img
                          src={InfoIcon}
                          alt='info'
                          width={8}
                          style={{ marginLeft: 3, }}
                        />

                      </p>
                    </div>
                  </DiscountTooltip>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>

        <div className='menuHours'>
          <p>{menuTime}</p>
        </div>
      </div>
    </div >
  );
};
