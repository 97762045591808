import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { cartReducer } from './cart/cart.reducer';
import { shopReducer } from './shop/shop.reducer';
import { userReducer } from './user/user.reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'],
};

const reducers = combineReducers({
  cart: cartReducer,
  user: userReducer,
  shop: shopReducer,
});

export default persistReducer(persistConfig, reducers);

// export default reducers;
