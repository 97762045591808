import moment from 'moment';

import { config } from 'config';
import { EDeliveryMode, ICartItem } from 'store/cart/cart.types';
import { IMenu, IInterval, IShope } from 'store/shop/shop.types';

import { parsedQueryString } from './parsedQueryString';
import { EStorePaymentGateway, IConfirmOrderPayload } from './types';

export function getQueryVariable(variable: string) {
  const query = window.location.search.substring(1);
  const vars = query?.split('&') || [];

  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');

    if (pair[0] == variable) {
      return pair[1];
    }
  }

  return false;
}

// export function groupBy(key) {
//   return function group(array) {
//     return array.reduce((acc, obj) => {
//       const property = obj[key];
//       acc[property] = acc[property] || [];
//       acc[property].push(obj);
//       return acc;
//     }, {});
//   };
// }

// export const isObjEmpty = (obj) => {
//   const emptyObj = !!(
//     obj && // 👈 null and undefined check
//     Object.keys(obj).length === 0 &&
//     Object.getPrototypeOf(obj) === Object.prototype
//   );
//   return emptyObj;
// };

export function calcTime(offset: string) {
  if (!offset) return null;

  // return moment().utcOffset('+05:30').format('YYYY-MM-DD HH:mm')
  return moment().utcOffset(offset).format('HH:mm');
}

export const compareTime = (time: IInterval, localTimeWithZone: any) => {
  const localTime = localTimeWithZone.split(':');
  const startTime = moment({
    h: time.fromHour,
    m: time.fromMinute,
  });

  const endTime = moment({
    h: time.toHour,
    m: time.toMinute,
  });

  // console.log(startTime.isBefore(endTime)); //true
  // console.log(moment({
  //   h: localTime[0],
  //   m: localTime[1],
  // }).isBetween(startTime, endTime))
  return moment({
    h: localTime[0],
    m: localTime[1],
  }).isBetween(startTime, endTime);
};

export const getShopMenu = (currentShop: IShope) => {
  const shopMenu = currentShop?.store_menu.menus;

  if (shopMenu) return Object.values(shopMenu)[0];
};

export function getTodayStoreTime(shopMenu: IMenu) {
  const DAYS = [
    'SUNDAY',
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY'
  ];
  const d = new Date();
  const day = DAYS[d.getDay()];
  const intervals = shopMenu?.hours.intervals;

  return intervals.filter((e) => e.day == day)[0];

  // if (intervals) {
  //   const matchedDay = intervals.filter((e) => e.day == day)[0];

  //   return `Menu Hours: ${matchedDay.fromHour}:${(
  //     '0' + matchedDay.fromMinute
  //   ).slice(-2)} to ${matchedDay.toHour}:${(
  //     '0' + matchedDay.toMinute
  //   ).slice(-2)}`
  // }

  // return null;
}

export const getMenuTime = (time: IInterval) => {

  return `Menu Hours: ${time?.fromHour}:${(
    '0' + time?.fromMinute
  ).slice(-2)} to ${time?.toHour}:${(
    '0' + time?.toMinute
  ).slice(-2)}`;
};

interface IPaymentGateway {
  orderId: string;
  paymentId: string;
  signature: string;
  receiptNumber: string;
}

export interface ICart {
  items: ICartItem[];
  subtotal: number;
  discount: number;
  couponCode: string;
  deliveryFee: number;
  tax: number;
  tip: number;
  total: number;
}

export const createConfirmOrderPayload = (store: IShope, paymentGateway: IPaymentGateway, cart: ICart, userInfo: any, deliveryInstructions: string, deliveryMode: string, orderNote: string): IConfirmOrderPayload => {

  const userName =
    EDeliveryMode.PICKUP && userInfo.tableNumber
      ? `${userInfo.name} | Table: ${userInfo.tableNumber} `
      : userInfo.name;

  const today = new Date();
  // today = today.toISOString().split('.')[0];
  today.setMilliseconds(0);
  const formattedToday = today.toISOString().replace('.000Z', 'Z');

  return {
    data: {
      metadata: {
        store_id: store?.store_details.store_id,
        // order_type: 'Wa', // const variable -- hard code it
        order_type: parsedQueryString.order_type ?? config.orderType,
        store_payment_gateway: store?.store_details.store_payment_gateway as EStorePaymentGateway,
        payment_gateway_order_id: paymentGateway.orderId,
        payment_gateway_payment_id: paymentGateway.paymentId,
        payment_gateway_signature: paymentGateway.signature,
        payment_gateway_receipt_number: paymentGateway.receiptNumber,
        payment_gateway_account_id:
          store?.store_details.store_pg_merchant_id,
      },

      order: {
        items: cart.items,
        // orderedAt: new Date(),
        orderedAt: formattedToday,
        currencyCode: store?.store_details.store_currency,
        customer: {
          name: userName ?? '',
          phone: `${userInfo.phone}` ?? '',
          email: '',
        },
        status: 'NEW_ORDER', // keep it same it will be static
        deliveryInfo: {
          destination: {
            postalCode: userInfo.pinCode ?? '',
            city: userInfo.city ?? '',
            state: userInfo.state ?? '',
            countryCode: store?.store_details.store_country,
            addressLines: [
              userInfo.address1 ?? '',
              userInfo.address2 ?? ''
            ],
            location: {
              latitude: '',
              longitude: '',
            },
          },
          lastKnownLocation: {
            latitude: '',
            longitude: '',
          },
          note: deliveryInstructions,
        },
        customerNote: orderNote,
        orderTotal: {
          subtotal: cart.subtotal,
          discount: cart.discount,
          couponCode: '',
          deliveryFee: cart.deliveryFee,
          tax: cart.tax,
          tip: 0,
          total: cart.total,
        },
        customerPayments: [
          // keep it same. it will be same as below
          {
            value: 0,
            processingStatus: 'PROCESSED',
            paymentMethod: 'CARD',
          }
        ],
        fulfillmentInfo: {
          fulfillmentMode: deliveryMode.toUpperCase(),
        },
      },
    },
  };
};

export const storeStripePayloadInLocalStorage = (payload: IConfirmOrderPayload): void => {
  localStorage.setItem('stripePayload', JSON.stringify(payload));
};

export const retrieveStripePayloadInLocalStorage = (): IConfirmOrderPayload | null => {
  const retrievedObject = localStorage.getItem('stripePayload');

  if (retrievedObject)
    return JSON.parse(retrievedObject);

  return null;
};

export const removeStripePayloadFromLocalStorage = () => {
  localStorage.removeItem('stripePayload');
};