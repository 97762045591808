/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import item1Image from 'assets/images/item1.png';
import menuImage from 'assets/images/menu.png';
import vegetarianImage from 'assets/images/vegetarian.png';
import AddToCart from 'components/AddToCart/AddToCart';
import { selectCartItemsCount } from 'store/cart/cart.selectors'
import {
  selectCurrentShop,
  selectShopCurrencySymbol,
  selectFormattedShopMenu
} from 'store/shop/shop.selectors';

import './Menu.css';

interface Props { }

export const Menu = (props: Props) => {
  const navigate = useNavigate();
  const { search, hash, } = useLocation();

  const [more, setMore] = useState(true);
  const currentShop = useSelector(selectCurrentShop);
  const completeMenu = useSelector(selectFormattedShopMenu);
  const currencySymbol = useSelector(selectShopCurrencySymbol);
  const photos = currentShop?.store_menu.photos;

  const cartItemsCount = useSelector(selectCartItemsCount)

  useEffect(() => {

    if (hash && document.getElementById(hash.substr(1))) {
      // Check if there is a hash and if an element with that id exists
      const element = document.getElementById(hash.substr(1))

      if (element)
        element
          .scrollIntoView({ behavior: 'smooth', });
    }
  }, [hash]); // Fires when component mounts and every time hash changes

  return (
    <div className='menuWrapper'>
      {completeMenu?.map((menuItem) => {
        return (
          <div
            id={menuItem.id}
            key={`${menuItem.id}__menu`}
            className='menudetailMain'
          >
            <h3 className='menu_heading'>{menuItem.name}</h3>

            {menuItem.items?.map((item) => {
              // const addToCartItem = {
              //   description: item?.description ?? '',
              //   id: item?.id ?? '',
              //   modifiers: item?.modifierGroupIds ?? [],
              //   name: item?.name ?? '',
              //   price: item?.price.amount ?? 0,
              //   note: '',
              // };

              return (
                <div
                  key={`${item?.id}__menu__item`}
                  className='menuDetails'
                  id={item?.id}
                >
                  <div className='menuList'>
                    <div className='menuinfo'>
                      <div
                        className={
                          item?.description ? 'itemList' : 'noDisscription'
                        }
                      >
                        <div className='leftItemlist'>
                          <div
                            style={{
                              display: 'flex',
                              gap: 15,
                            }}
                          >
                            <h6>{item?.name}</h6>

                            {/* <img src={vegetarian} alt='veg' /> */}
                          </div>

                          <p>
                            {more
                              ? item?.description
                                .split(' ')
                                .slice(0, 15)
                                .join(' ')
                              : item?.description}

                            <a
                              style={{
                                color: '#ff9800',
                                cursor: 'pointer',
                              }}
                              onClick={() => setMore(!more)}
                            >
                              {' '}

                              {item &&
                                item?.description?.split(' ')?.length > 15
                                ? more
                                  ? 'more..'
                                  : 'Less'
                                : ''}
                            </a>
                          </p>
                        </div>

                        <div className='itemPrice'>
                          {/* <img src={vegetarian} alt='veg' /> */}

                          <h2 style={{ margin: 0, }}>
                            {currencySymbol}

                            {item?.price.amount}
                          </h2>
                        </div>
                      </div>
                    </div>

                    {item?.photoIds?.[0] &&
                      photos?.[item?.photoIds?.[0]]?.url ? (
                      <div className='menuimage'>
                        <div className='itemImage'>
                          <div
                            style={{
                              backgroundImage: `url(${photos?.[item?.photoIds?.[0]]?.url
                                })`,
                              width: '75px',
                              height: '75px',
                              borderRadius: '5px',
                              backgroundSize: 'cover',
                              backgroundPosition: 'center center',
                              backgroundRepeat: 'no-repeat',
                            }}
                          ></div>

                          {/* <img
                        src={photos[items[itemKey].photoIds]?.url}
                        // width='75px'
                        // height='75px'
                        alt='pizza'
                      /> */}

                          <AddToCart item={item} />
                        </div>
                      </div>
                    ) : (
                      <div className='menuimage'>
                        <div className='itemImage'>
                          <img
                            src={item1Image}
                            alt='pizza'
                          />

                          {item && <AddToCart item={item} />}

                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}

      <div className='menuBar'>
        <div
          className='menubottom'
          onClick={() => navigate(`/allCategories${search}`)}
        >
          <img
            src={menuImage}
            alt='menuIcon'
          />

          <p>Menu</p>
        </div>
      </div>

      <div className='viewCart'>
        <button
          disabled={cartItemsCount <= 0}
          onClick={() => navigate(`/placeOrder${search}`)}
        >
          VIEW CART
          ({cartItemsCount})
        </button>
      </div>
    </div>
  );
};
