import { ICartItem, IModifier } from './cart.types';

export const addSimpleItemToCart = (
  cartItems: ICartItem[],
  cartItemToAdd: ICartItem
): ICartItem[] => {
  const existingCartItem = cartItems.find(
    (cartItem: ICartItem) => cartItem.id === cartItemToAdd.id
  );

  if (existingCartItem) {
    return cartItems.map((cartItem: ICartItem) =>
      cartItem.id === cartItemToAdd.id
        ? {
          ...cartItem,
          quantity: (cartItem.quantity ?? 0) + 1,
        }
        : cartItem
    );
  } else {
    cartItemToAdd.quantity = 1;
  }

  return [...cartItems, cartItemToAdd];
};

export const addComplexItemToCart = (
  cartItems: ICartItem[],
  cartItemToAdd: ICartItem
): ICartItem[] => {

  cartItemToAdd.quantity = 1;

  return [...cartItems, cartItemToAdd];
};

export const removeItemFromCart = (
  cartItems: ICartItem[],
  cartItemToRemove: ICartItem
) => {
  const existingCartItem = cartItems.find(
    (item: ICartItem) => item.id === cartItemToRemove.id
  );

  if (existingCartItem && existingCartItem.quantity === 1) {
    return cartItems.filter(
      (item: ICartItem) => item.id !== cartItemToRemove.id
    );
  }

  return cartItems.map((item: ICartItem) =>
    item.id === cartItemToRemove.id
      ? {
        ...item,
        quantity: (item.quantity ?? 0) - 1,
      }
      : item
  );
};

export const calculateCartSubTotalReducer = (acc: number, item: ICartItem) => {

  const modifiers = item.modifiers as IModifier[]
  const modifiersTotal = modifiers.reduce((acc: number, modifier: IModifier) => acc + (modifier.quantity ?? 0) * modifier?.price, 0)

  return acc + (item.quantity ?? 0) * item?.price + modifiersTotal

  // if (item.modifiers.length === 0)
  //   return acc + (item.quantity ?? 0) * item?.price
  // else if (item.modifiers.length > 0) {
  //   const modifiers = item.modifiers as IModifier[]
  //   const modifiersTotal = modifiers.reduce((acc: number, modifier: IModifier) => acc + (modifier.quantity ?? 0) * modifier?.price, 0)

  //   return acc + (item.quantity ?? 0) * item?.price + modifiersTotal
  // }
  // else return 0

}
