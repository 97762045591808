/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import * as cartActionCreator from 'store/cart/cart.action.creator';
import { selectCartItemQuantity } from 'store/cart/cart.selectors';
import { ICartItem } from 'store/cart/cart.types';
import { IShopItem } from 'store/shop/shop.types';

interface Props {
  item: IShopItem;
}

// interface IAddToCart extends Omit<ICartItem, 'modifiers'> {
//   modifiers: string[];
// }

// interface Props {
//   item: IAddToCart;
// }

const AddToCart = ({ item, }: Props) => {
  const navigate = useNavigate();
  const { search, } = useLocation();
  const dispatch = useDispatch();

  const modifiersLength = item?.modifierGroupIds?.length ?? 0;

  //? To make sure we get error with modifier in ICartItem
  // const clickedItem = {
  //   ...item,
  //   modifiers: [],
  // };

  const selectedItemQuantity = useSelector(selectCartItemQuantity(item));

  const { addSimpleItemToCart, removeItemFromCart, } = bindActionCreators(
    cartActionCreator,
    dispatch
  );

  const addToCartItem = {
    description: item?.description ?? '',
    id: item?.id ?? '',
    modifiers: item?.modifierGroupIds ?? [],
    name: item?.name ?? '',
    price: item?.price.amount ?? 0,
    note: '',
  };

  const handleSimpleItem = () => {
    addSimpleItemToCart(addToCartItem);
  };

  const handleComplexItem = () => {
    navigate(`/item/${item.id}${search}`);
  };

  const addCartElement = (
    <button
      onClick={modifiersLength > 0 ? handleComplexItem : handleSimpleItem}
    >
      + Add
    </button>
  );

  const plusMinusElement = (
    <button
      style={{
        display: 'flex',
        padding: '1px 6px',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{ padding: '0px 5px', }}
        onClick={() => removeItemFromCart(addToCartItem)}
      >
        -
      </div>

      <div>{selectedItemQuantity}</div>

      <div
        style={{ padding: '0px 5px', }}
        onClick={modifiersLength > 0 ? handleComplexItem : handleSimpleItem}
      >
        +
      </div>
    </button>
  );

  return <>{!selectedItemQuantity ? addCartElement : plusMinusElement}</>;
};

export default AddToCart;
