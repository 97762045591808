import { useSelector } from 'react-redux';

import {
  selectCartDeliveryFee,
  selectCartDiscount,
  selectCartItems,
  selectCartSubTotal,
  selectCartTax,
  selectCartTotal,
  selectDeliveryMode
} from 'store/cart/cart.selectors';
import { selectShopCurrencySymbol } from 'store/shop/shop.selectors';

export const useCartSummary = () => {
  const cartItems = useSelector(selectCartItems);
  const deliveryMode = useSelector(selectDeliveryMode);
  const deliveryFee = useSelector(selectCartDeliveryFee);
  const cartSubTotal = useSelector(selectCartSubTotal);
  const cartDiscount = useSelector(selectCartDiscount);
  const cartTax = useSelector(selectCartTax);
  const cartTotal = useSelector(selectCartTotal);
  const currencySymbol = useSelector(selectShopCurrencySymbol);

  return {
    cartItems,
    deliveryMode,
    deliveryFee,
    cartSubTotal,
    cartDiscount,
    cartTax,
    cartTotal,
    currencySymbol,
  };
};
