/**
 *? Interface is kind of type. You can easily convert interface into type.
 *? If you want, you can create a separate file for interface
 */

import React from 'react';

export enum EShopActionTypes {
  FETCH_SHOP_START = 'FETCH_SHOP_START',
  FETCH_SHOP_SUCCESS = 'FETCH_SHOP_SUCCESS',
  FETCH_SHOP_FAILURE = 'FETCH_SHOP_FAILURE',
}

interface IFetchShopFailure {
  type: EShopActionTypes.FETCH_SHOP_FAILURE;
  payload: string | React.ReactNode;
}
interface IFetchShopSuccess {
  type: typeof EShopActionTypes.FETCH_SHOP_SUCCESS;
  payload: IShope;
}

export interface IShopeState {
  currentShop: IShope | null;
  errorMessage: string | null | React.ReactNode;
  currencySymbol: TCurrencySymbol;
}

export type TCurrencySymbol = string | undefined | null;

export type TShopActions = IFetchShopFailure | IFetchShopSuccess;

//?  --------------------------------- DB data to Typescript -----------------------------------
export interface IShope {
  _id: string;
  _key: string;
  _rev: string;
  store_details: IStoreDetails;
  store_menu: IStoreMenu;
}

export interface IStoreDetails {
  'auto_accept': boolean;
  brand_id: string;
  'discount_type': string;
  'has_delivery': boolean;
  'has_dinein': boolean;
  'has_pickup': boolean;
  'has_restaurant_delivery': boolean;
  store_country: string;
  store_country_code: string;
  // store_currency:              StoreCurrency;
  store_currency: string;
  store_delivery_fee: number;
  store_delivery_time_in_mins: number;
  store_discount_amount: string;
  store_discount_max_amount: number;
  store_discount_min_bill: string;
  store_discount_percent: string;
  store_discount_percent_max_amount: string;
  store_id: string;
  store_image_url: string;
  store_loc: string;
  'store_min_amt_dlvry': string;
  store_name: string;
  store_payment_gateway: string;
  store_pg_merchant_id: string;
  store_phone: string;
  store_prep_time_in_mins: number;
  store_rp_merchant_id: string;
  store_tax_rate: number;
  store_timezone: string;
  store_whatsapp_phone: string;
}

export enum EStoreCurrency {
  Inr = 'INR',
}

export interface IStoreMenu {
  categories: { [key: string]: ICategory };
  items: { [key: string]: IShopItem };
  menus: IMenus;
  modifierGroups: { [key: string]: IModifierGroup };
  photos: { [key: string]: IPhoto };
}

export interface ICategory {
  id: string;
  itemIds: string[];
  name: string;
}

export interface IShopItem {
  description: string;
  id: string;
  modifierGroupIds: string[];
  name: string;
  photoIds: string[];
  price: IPrice;
  status: IStatus;
}

export interface IPrice {
  amount: number;
  // currencyCode: StoreCurrency;
  currencyCode: string;
}

export interface IStatus {
  // saleStatus: SaleStatus;
  saleStatus: string;
}

export enum ESaleStatus {
  ForSale = 'FOR_SALE',
}

export interface IMenus {
  [key: string]: IMenu;
}

export interface IMenu {
  categoryIds: string[];
  description: string;
  fulfillmentModes: any[];
  hours: IHours;
  id: string;
  name: string;
}

export interface IHours {
  intervals: IInterval[];
}

export interface IInterval {
  day: string;
  fromHour: number;
  fromMinute: number;
  toHour: number;
  toMinute: number;
}

export interface IModifierGroup {
  id: string;
  itemIds: string[];
  maxPerModifierSelectionQuantity: number;
  maximumSelections: number;
  minimumSelections: number;
  name: string;
}

export interface IPhoto {
  // contentType: ContentType;
  contentType: string;
  fileName: string;
  id: string;
  url: string;
}

export enum IContentType {
  Jpg = 'JPG',
}
