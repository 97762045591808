
import { Modal, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { clearCart } from 'store/cart/cart.action.creator';
import { TCurrencySymbol } from 'store/shop/shop.types';
import { IServerOrderResponse } from 'utils/types';
import './Modal.css';

type TMessage = IServerOrderResponse['message'];
interface Props {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  message: TMessage;
  errorMessage: string | null;
  currencySymbol: TCurrencySymbol;
}

export const CustomModal = ({
  showModal,
  setShowModal,
  message,
  errorMessage,
  currencySymbol,
}: Props) => {
  const navigate = useNavigate();
  const { search, } = useLocation();

  const dispatch = useDispatch();

  return (
    <>
      <Modal
        className='successfullyorderModal'
        show={showModal}
        centered
        onHide={() => setShowModal(false)}
      >
        <Modal.Body>
          {message?.message && (
            <>
              <p>{message.message}</p>

              <p>
                <b>Order Id: </b> {message.order_id}
              </p>

              <p className='totalPera'>
                <b>Total: </b> {currencySymbol} {message.order_total}
              </p>

              <div className='backBtn'>
                <Button
                  variant='secondary'
                  onClick={() => {
                    setShowModal(false);
                    dispatch(clearCart());
                  }}
                >
                  Back
                </Button>
              </div>
            </>
          )}

          {errorMessage && (
            <>
              <p>{errorMessage}</p>

              <div className='backBtn'>
                <Button
                  variant='secondary'
                  className='mx-2'
                  onClick={() => {
                    setShowModal(false);
                    dispatch(clearCart());
                  }}
                >
                  No thanks!
                </Button>

                <Button
                  variant='secondary'
                  className='mx-2'
                  onClick={() => navigate(`/placeOrder${search}`)}
                >
                  Retry
                </Button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
