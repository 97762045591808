import React from 'react'

import { IShope } from 'store/shop/shop.types'

export const TimeZoneError = (shop: IShope, shopMenuTime: string) => {

  return (
    <div>
      <h5>{shop.store_details.store_name} - {shopMenuTime}</h5>

      <p>Currently store is closed. Please visit later </p>
    </div>
  )
}

