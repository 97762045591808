import queryString from 'query-string'

export interface IParsedQueryString {
  brand_id?: string;
  store_id: string;
  order_type?: string;
  table_number?: string;
  fulfillment_mode?: string;
}

const search = window.location.search;
export const parsedQueryString = queryString.parse(search) as unknown as IParsedQueryString;