import React, { useState, useEffect, useRef } from 'react';

import { Modal } from 'react-bootstrap';

import { config } from 'config';
import { IShope } from 'store/shop/shop.types';
import { getNewOrderId } from 'utils/agent';
import { createConfirmOrderPayload, ICart, removeStripePayloadFromLocalStorage, storeStripePayloadInLocalStorage } from 'utils/helper';
import { INewOrderIdStripeResponse } from 'utils/types';
import './Stripe.css';

interface Props {
  showStripeModal: boolean;
  setShowStripeModal: (showStripeModal: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
  store: IShope;
  receipt: string;
  totalAmount: number;
  cart: ICart;
  deliveryInstructions: string;
  deliveryMode: string;
  orderNote: string;
  userInfo: any;
}

export const StripeModal = ({
  showStripeModal,
  setShowStripeModal,
  setIsLoading: setIsLoadingParent,
  store,
  receipt,
  totalAmount,
  cart,
  deliveryInstructions,
  deliveryMode,
  orderNote,
  userInfo,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const elements: any = useRef(null);

  const stripRef = useRef(
    (window as any).Stripe(config.stPartnerKey, {
      stripeAccount: store?.store_details.store_pg_merchant_id,
    })
  );

  const stripe = stripRef.current;

  async function handleStripePaymentSubmit(e: React.FormEvent) {
    e.preventDefault();
    setIsLoading(true);
    const stripeElements: any = elements.current;

    try {
      const { error, } = await stripe.confirmPayment({
        elements: stripeElements,
        confirmParams: {
          // Make sure to change this to your payment completion page, this is the react UI app redirect
          return_url: `${window.location.href}`,
        },
      });

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error.type === 'card_error' || error.type === 'validation_error') {
        console.log('error.type  ', error.type);
        // showMessage(error.message);
      } else {
        // showMessage('An unexpected error occured.');
      }

      setIsLoading(false);
    } catch (error) {
      //
    }
  }

  useEffect(() => {

    // Fetches a payment intent and captures the client secret
    async function initialize() {

      setIsLoading(true);
      const newOrder = await getNewOrderId(
        store,
        totalAmount,
        receipt
      ) as unknown as INewOrderIdStripeResponse;

      const appearance = { theme: 'stripe', };

      elements.current = stripe.elements({
        appearance,
        clientSecret: newOrder.clientSecret,
      });

      if (elements.current) {
        const paymentElement = elements.current.create('payment');
        paymentElement.mount('#payment-element');

        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      }

      const paymentGateway = {
        orderId: newOrder.payment_gateway_order_id,
        paymentId: newOrder.payment_gateway_payment_id,
        signature: newOrder.clientSecret,
        receiptNumber: receipt,
      };

      const payload = createConfirmOrderPayload(store, paymentGateway, cart, userInfo, deliveryInstructions, deliveryMode, orderNote);

      storeStripePayloadInLocalStorage(payload);

    }

    // Fetches the payment intent status after payment submission
    async function checkStatus() {
      const clientSecret = new URLSearchParams(window.location.search).get(
        'payment_intent_client_secret'
      );

      if (!clientSecret) {
        return;
      }

      const { paymentIntent, } = await stripe.retrievePaymentIntent(
        clientSecret
      );

      // switch (paymentIntent.status) {
      //   case 'succeeded':
      //     showMessage('Payment succeeded!');
      //     break;

      //   case 'processing':
      //     showMessage('Your payment is processing.');
      //     break;

      //   case 'requires_payment_method':
      //     showMessage('Your payment was not successful, please try again.');
      //     break;

      //   default:
      //     showMessage('Something went wrong.');
      //     break;
      // }
    }

    initialize();
    checkStatus();
    
    // document.querySelector('#payment-form').addEventListener('submit', handleSubmit);

  }, [cart, deliveryInstructions, deliveryMode, orderNote, receipt, store, stripe, totalAmount, userInfo]);

  return (
    <Modal
      className='stripe__form__payment_modal'
      show={showStripeModal}
      centered
      onHide={() => {
        setShowStripeModal(false);
        setIsLoadingParent(false);
        removeStripePayloadFromLocalStorage();
      }}
    >
      <Modal.Body>
        <form
          id='payment-form'
          onSubmit={handleStripePaymentSubmit}
          className='stripe__form'
        >
          <h3>{store?.store_details.store_name}</h3>

          <div id='payment-element'>
            {/* <!--Stripe.js injects the Payment Element--> */}
          </div>

          <button
            id='submit'
            type='submit'
            disabled={isLoading}
          >
            <div
              className={`spinner ${!isLoading && 'hidden'}`}
              id='spinner'
            ></div>

            <span
              id='button-text'
              className={`${isLoading && 'hidden'}`}
            >
              Pay now
            </span>
          </button>

          <div
            id='payment-message'
            className='hidden'
          ></div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
