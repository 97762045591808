import React, { useContext, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useLocation, useNavigate, Link } from 'react-router-dom';

import Categoriesimage from 'assets/images/categoriesimage.png';
import {
  selectCurrentShop,
  selectFormattedShopMenu
} from 'store/shop/shop.selectors';

import './AllCategories.css';

export const AllCategories = () => {
  const navigate = useNavigate();
  const { search, } = useLocation();

  const currentShop = useSelector(selectCurrentShop);
  const photos = currentShop?.store_menu.photos;
  const completeMenu = useSelector(selectFormattedShopMenu);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className='categoriesmenuWrapper'>
        <div className='categoriesTitle'>
          <h5>All Categories</h5>
        </div>

        <div className='allCategoriesSection'>
          {completeMenu?.map((menuItem) => {
            const photoId = menuItem.items?.[0]?.photoIds[0];

            const photo = photos?.[photoId!];

            return (
              <div key={`menuinfo__${menuItem.id}`}>
                <Link to={`/${search}#${menuItem.id}`}>
                  {/* <img
                      src={photo?.url || Categoriesimage}
                      alt={photo?.fileName || 'Categoriesimage'}
                    /> */}

                  <div className={'backgroundImage__menu_item'}>
                    <div
                      style={{
                        backgroundImage: `url(${photo?.url || Categoriesimage
                          })`,
                        width: '100%',
                        height: '100%',
                        borderRadius: '5px',
                        filter: 'brightness(35%)',
                        backgroundRepeat: 'round',
                        // backgroundSize: 'cover',
                        // objectFit: 'contain',
                        // objectFit: 'cover',
                        // backgroundRepeat: 'no-repeat',
                      }}
                    ></div>

                    <h6>{menuItem.name}</h6>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>

      <div className='bottomfixbtn'>
        <button onClick={() => navigate(`/${search}`)}>Go Back</button>
      </div>
    </div>
  );
};
