/**
 ** snake_case style    -->   Data coming from server + payload  + shop
 ** camelCase           -->   js style
 */
import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { ErrorModal, Header } from 'components';
import { setCurrentShop } from 'store/shop/shop.action.creator';
import { selectShopError } from 'store/shop/shop.selectors';
import { parsedQueryString } from 'utils/parsedQueryString'

import Router from './routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  const dispatch = useDispatch();

  const shopErrorMessage = useSelector(selectShopError);

  useEffect(() => {
    dispatch(setCurrentShop(parsedQueryString));
  }, [dispatch]);

  const TheContent = (
    <>
      <Header />

      <Router />
    </>
  );

  return (
    <>
      {shopErrorMessage ? (
        <ErrorModal errorMessage={shopErrorMessage} />
      ) : (
        TheContent
      )}
    </>
  );
}

export default App;
