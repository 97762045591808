/**
 *? Interface is kind of type. You can easily convert interface into type.
 *? If you want, you can create a separate file for interface
 */

export enum EUserActionTypes {
  SET_CURRENT_USER_ADDRESS = 'SET_CURRENT_USER_ADDRESS',
}

export interface IAddress {
  name: string;
  phone: string;
  address1: string;
  address2: string;
  landmark: string;
  city: string;
  state: string;
  pinCode: string;
  country: string;
  selected: boolean;
}

export type TAddressTag = 'Home' | 'Office' | 'Other';

// export type TAddresses = { [key in TAddressTag]: IAddress };

export interface IAddAddress {
  Home?: IAddress;
  Office?: IAddress;
  Other?: IAddress;
}

export interface ICurrentUserAddress {
  address: IAddAddress;
}

export type TUser = ICurrentUserAddress | null;

export interface IUserState {
  currentUser: TUser;
}

export interface ISetCurrentUserAddress {
  type: EUserActionTypes.SET_CURRENT_USER_ADDRESS;
  payload: ICurrentUserAddress;
}

export type TUserActions = ISetCurrentUserAddress;
