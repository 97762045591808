import { useRoutes } from 'react-router-dom';

import {
  Menu,
  AllCategories,
  Item,
  PlaceOrder,
  ProcessToPay,
  PostOrder,
  DeliveryAddress

} from 'pages';

interface Props { }

const Routes = (props: Props) => {
  return useRoutes([
    // Main Routes
    {
      path: '/',
      element: <Menu />,
    },
    {
      path: '/allCategories',
      element: <AllCategories />,
    },
    {
      path: 'item/:itemID',
      element: <Item />,
    },
    {
      path: 'placeOrder',
      element: <PlaceOrder />,
    },
    {
      path: 'processToPay',
      element: <ProcessToPay />,
    },
    {
      path: 'postOrder',
      element: <PostOrder />,
    },
    {
      path: 'deliveryAddress',
      element: <DeliveryAddress />,
    }
    // {
    //   path: '*',
    //   element: <Navigate
    //     to='/404'
    //     replace
    //   />,
    // }
  ]);
};
export default Routes;
