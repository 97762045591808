import { Dispatch } from 'redux';

import { RootState } from '..';
import { EUserActionTypes, TUserActions, IAddAddress, TAddressTag } from './user.types';

export const setCurrentUserAddress = (userAddress: IAddAddress) => {

  return (dispatch: Dispatch<TUserActions>, getState: () => RootState) => {

    const { user, } = getState();
    const address = user.currentUser?.address

    if (address) Object.keys(address)?.forEach((key, index) => {
      const addressTag = key as TAddressTag;

      if (address[addressTag])
        address[addressTag]!.selected = false;
    });

    Object.keys(userAddress)?.forEach((key, index) => {
      const addressTag = key as TAddressTag;
      userAddress[addressTag]!.selected = true;
    });

    dispatch({
      type: EUserActionTypes.SET_CURRENT_USER_ADDRESS,
      payload: {
        address: {
          ...address,
          ...userAddress,
        },
      },
    });
  };
};
