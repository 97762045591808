/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useState } from 'react';

import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import LoadingOverlay from 'react-loading-overlay';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import RightArrow from 'assets/images/Right-Arrow-Download-Transparent-PNG-Image.png';
import { PhoneNumber } from 'components';
import { config } from 'config';
import { useCartSummary } from 'hooks/useCartSummary';
import {
  addPayloadItemsToCart,
  toggleDeliveryMode
} from 'store/cart/cart.action.creator';
import { EDeliveryMode } from 'store/cart/cart.types';
import { selectCurrentShop } from 'store/shop/shop.selectors';
import { setCurrentUserAddress } from 'store/user/user.action.creator';
import {
  selectCurrentUser,
  selectSelectedAddress
} from 'store/user/user.selectors';
import { TAddressTag } from 'store/user/user.types';
import { getNewOrderId, postConfirmOrder } from 'utils/agent';
import {
  createConfirmOrderPayload,
  removeStripePayloadFromLocalStorage,
  retrieveStripePayloadInLocalStorage
} from 'utils/helper';
import { parsedQueryString } from 'utils/parsedQueryString';
import {
  EStorePaymentGateway,
  IConfirmOrderPayload,
  INewOrderIdRazorpayResponse,
  IRazorpayResponse
} from 'utils/types';

import './ProcessToPay.css';
import { PaymentFailedModal } from './PaymentFailedModal';
import { StripeModal } from './StripeModal';

interface IPickUpFormInputs {
  name: string;
  phone: string;
  tableNumber: string;
}

export const ProcessToPay = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isStripePaymentFailed, setIsStripePaymentFailed] = useState(false);
  const [showStripeModal, setShowStripeModal] = useState(false);

  const {
    cartItems,
    deliveryMode,
    deliveryFee,
    cartSubTotal,
    cartDiscount,
    cartTax,
    cartTotal,
    currencySymbol,
  } = useCartSummary();

  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  const currentShop = useSelector(selectCurrentShop);
  const currentUser = useSelector(selectCurrentUser);
  const address = currentUser?.address;

  const selectedAddress = useSelector(selectSelectedAddress);

  const [deliveryInstructions, setDeliveryInstructions] = useState('');

  const isCartEmpty = cartItems.length === 0;

  const storePaymentGateway =
    currentShop?.store_details.store_payment_gateway || '';

  const receipt = String(Math.floor(1000 + Math.random() * 9000));
  const totalAmount = Number((cartTotal * 100).toFixed(2));
  const cart = {
    items: cartItems,
    subtotal: cartSubTotal,
    discount: cartDiscount,
    couponCode: '',
    deliveryFee: deliveryFee,
    tax: cartTax,
    tip: 0,
    total: cartTotal,
  };

  const callConfirmOrder = useCallback(
    async (payload: IConfirmOrderPayload) => {
      setIsLoading(true);
      const confirmOrder = await postConfirmOrder(payload);
      const result = await confirmOrder.text();
      setIsLoading(false);

      removeStripePayloadFromLocalStorage();

      navigate(`/postOrder${location.search}`, {
        state: { serverResponse: JSON.parse(result), },
      });
    },
    [location.search, navigate]
  );

  const [userInfoStripe, setUserInfoStripe] = useState<
    Partial<typeof selectedAddress> & Partial<IPickUpFormInputs>
  >();

  const handlePayment = async (
    store: typeof currentShop,
    userInfo:
      | (Partial<typeof selectedAddress> & Partial<IPickUpFormInputs>)
      | null
      | undefined
  ) => {
    if (!store || !userInfo) return;

    setIsLoading(true);

    try {
      if (storePaymentGateway === EStorePaymentGateway.RAZORPAY) {
        const newOrder = (await getNewOrderId(
          store,
          totalAmount,
          receipt
        )) as unknown as INewOrderIdRazorpayResponse;

        if (newOrder?.message?.id) {
          const razorpayOptions = {
            key: config?.rpPartnerKey,
            amount: totalAmount,
            currency: store?.store_details.store_currency,
            name: store?.store_details.store_name,
            description: '',
            image:
              'https://www.publicdomainpictures.net/pictures/30000/nahled/plain-white-background.jpg', // TODO: download this file and load it as static
            order_id: newOrder?.message?.id,
            account_id: store?.store_details.store_pg_merchant_id,
            // prefill: {
            //   email: 'test@gmail.com',
            //   contact: '919191919991',
            //   name: 'Razorpay Software',
            //   //       'method': 'netbanking', //card|upi|wallet
            // },
            handler: async function (response: IRazorpayResponse) {
              const {
                razorpay_payment_id,
                razorpay_order_id,
                razorpay_signature,
              } = response;

              const paymentGateway = {
                orderId: razorpay_order_id,
                paymentId: razorpay_payment_id,
                signature: razorpay_signature,
                receiptNumber: receipt,
              };

              // const payload = createConfirmOrderPayload(
              //   store,
              //   paymentGateway,
              //   cart,
              //   userInfo,
              //   deliveryInstructions,
              //   deliveryMode,
              //   location?.state?.orderNote
              // );

              // callConfirmOrder(payload);
            },

            modal: {
              ondismiss: function () {
                setIsLoading(false);
              },
            },
          };

          const rzp1 = new (window as any).Razorpay(razorpayOptions);
          rzp1.open();

          rzp1.on('payment.failed', function (response: any) {
            alert(response.error.code);
            alert(response.error.description);
            alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.order_id);
            alert(response.error.metadata.payment_id);
          });
        }
      } else if (storePaymentGateway === EStorePaymentGateway.STRIPE) {
        setShowStripeModal(true);
        setUserInfoStripe(userInfo);
        //
      }
    } catch (error) {
      //
    }
  };

  const defaultValues = {
    name: '',
    phone: '',
    tableNumber: parsedQueryString.table_number ?? '',
  };

  const {
    watch,
    reset,
    control,
    register,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, },
  } = useForm<IPickUpFormInputs>({
    // mode: 'onTouched',
    // mode: 'onBlur', //? The blur event fires when an element has lost focus
    mode: 'onSubmit',
    defaultValues,
  });

  const onSubmit: SubmitHandler<IPickUpFormInputs> = (data) => {
    handlePayment(currentShop, data);
  };

  // useEffect(() => {
  //   const payload = retrieveStripePayloadInLocalStorage();

  //   if (payload) {
  //     callConfirmOrder(payload);
  //   } else if (isCartEmpty) navigate(`/${location.search}`);
  // }, [isCartEmpty, navigate, location.search, callConfirmOrder]);

  const checkStatus = useCallback(async () => {
    const stripe = (window as any).Stripe(config.stPartnerKey, {
      stripeAccount: currentShop?.store_details.store_pg_merchant_id,
    });

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    if (!clientSecret) {
      return;
    }

    const { paymentIntent, error, } = await stripe.retrievePaymentIntent(
      clientSecret
      );

    const payload = retrieveStripePayloadInLocalStorage();

    if (paymentIntent?.status === 'succeeded') {
      if (payload) {
        callConfirmOrder(payload);
      } else if (isCartEmpty) navigate(`/${location.search}`);
    } else {
      setIsStripePaymentFailed(true);
      // removeStripePayloadFromLocalStorage();
      const fulfillmentMode = payload?.data.order.fulfillmentInfo.fulfillmentMode;

      const items = payload?.data.order.items;
      dispatch(toggleDeliveryMode(fulfillmentMode as unknown as EDeliveryMode));
  
      if (items) dispatch(addPayloadItemsToCart(items));
  
      if (fulfillmentMode === EDeliveryMode.PICKUP) {
        setValue('name', payload?.data.order.customer.name ?? '');
        setValue('phone', payload?.data.order.customer.phone??' ');
      }
  
    }
    // switch (paymentIntent.status) {
    //   case 'succeeded':
    //     showMessage('Payment succeeded!');
    //     break;

    //   case 'processing':
    //     showMessage('Your payment is processing.');
    //     break;

    //   case 'requires_payment_method':
    //     showMessage('Your payment was not successful, please try again.');
    //     break;

    //   default:
    //     showMessage('Something went wrong.');
    //     break;
    // }
  }, [
    callConfirmOrder,
    currentShop?.store_details.store_pg_merchant_id,
    isCartEmpty,
    location.search,
    navigate
  ]);

  useEffect(() => {
    if (currentShop?.store_details.store_pg_merchant_id) {
      checkStatus();
      // setTimeout(() => {
      // }, 2000);
    }
  }, [checkStatus, currentShop?.store_details.store_pg_merchant_id]);

  useEffect(() => {
    if (
      location?.state?.shouldProcessToPay &&
      deliveryMode === EDeliveryMode.DELIVERY
    )
      navigate(-1);
  }, [deliveryMode, location?.state?.shouldProcessToPay, navigate]);

  return (
    <>
      <LoadingOverlay
        active={isLoading}
        spinner
        text='Placing the order...'
        styles={{
          overlay: (base) => ({
            ...base,
            // background: 'rgba(255, 255, 255, 0.7)',
            height: '100vh',
            zIndex: 10,
          }),
          // content: base => ({
          //   ...base,
          //   marginTop: props.marginTop,
          // }),
        }}
      >
        {/* <p>Some content or children or something.</p> */}
      </LoadingOverlay>

      <div className='ProcessToPayWrapper'>
        {/* <StripePaymentCheckoutForm /> */}

        <div className='totalOrder'>
          <p>Total</p>

          <p>
            {currencySymbol} {cartTotal}
          </p>
        </div>

        {/* TODO: Make separate component for delivery and pickup. It would have been easy if we were using local css */}

        {deliveryMode === EDeliveryMode.DELIVERY && (
          <>
            <div className='addressWrap'>
              <div className='addressHeading'>
                <h5>
                  Address<sup>*</sup>
                </h5>

                <button
                  onClick={() => {
                    navigate(`/deliveryAddress${location.search}`);
                  }}
                >
                  + Add Address
                </button>
              </div>

              {address ? (
                Object.keys(address).map((key, index) => {
                  const addressTag = key as TAddressTag;

                  return (
                    <div
                      key={key}
                      className={`ProcessToPay__addresses ${
                        address[addressTag]?.selected && 'selected'
                      }`}
                      onClick={() => {
                        dispatch(
                          setCurrentUserAddress({
                            [addressTag]: address[addressTag],
                          })
                        );
                      }}
                    >
                      {key}

                      <br />

                      {address[addressTag]?.name}

                      <br />

                      {address[addressTag]?.phone}

                      <br />

                      {address[addressTag]?.address1}{' '}

                      {address[addressTag]?.address2} <br />

                      {address[addressTag]?.city} {address[addressTag]?.state}{' '}

                      {address[addressTag]?.country}{' '}

                      {address[addressTag]?.pinCode}

                      <img
                        src={RightArrow}
                        alt='Right arrow'
                        width={24}
                        height={24}
                      />
                    </div>
                  );
                })
              ) : (
                <div className='ProcessToPay__addresses notSelected'>
                  Add address to proceed
                </div>
              )}
            </div>

            <div className='addressWrap'>
              <div className='addressHeading'>
                <h5>Delivery Instructions</h5>
              </div>

              <textarea
                placeholder='Add delivery instructions here'
                rows={4}
                onChange={({ target, }) => setDeliveryInstructions(target.value)}
              ></textarea>
            </div>

            <div className='process__pay__bottomfixbtn'>
              <button
                disabled={!address}
                onClick={() => handlePayment(currentShop, selectedAddress)}
              >
                Proceed to pay
              </button>
            </div>
          </>
        )}

        {deliveryMode === EDeliveryMode.PICKUP && (
          <>
            <form
              id='hook-form'
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className='ProcessToPay__addresses__pickup'>
                <div className='form-group mb-4'>
                  <label
                    htmlFor='name'
                    className='mb-3'
                  >
                    Name <span>*</span>
                  </label>

                  <input
                    type='text'
                    id='name'
                    className={`form-control  form-control-lg ${
                      errors.name ? 'is-invalid' : ''
                    }`}
                    {...register('name', { required: true, })}
                  />
                </div>

                <div className='form-group mb-4'>
                  <label
                    htmlFor='phone'
                    className='mb-3'
                  >
                    Phone Number <span>*</span>
                  </label>

                  {/* <input
                    id='phone'
                    {...register('phone', { required: true, })}
                    type='text'
                    maxLength={10}
                    pattern='\d{10}'
                    className={`form-control ${errors.phone ? 'is-invalid' : ''
                      }`}
                  /> */}

                  <Controller
                    control={control}
                    //    defaultValue={phone}
                    name={'phone'}
                    rules={{ required: true, }}
                    render={({ field: { value, onChange, onBlur, }, }) => (
                      <>
                        <PhoneNumber
                          // inputClass={'form-control'}
                          containerClass={`form-control ${
                            errors.phone ? 'is-invalid' : ''
                          }`}
                          inputStyle={{
                            minWidth: '100%',
                            maxWidth: '100%',
                            backgroundColor: 'transparent',
                            border: '0px',
                            outline: 'none',
                            WebkitBoxShadow: 'none',
                            MozBoxShadow: 'none',
                            boxShadow: 'none',
                            color: 'transparent',
                            cursor: 'default',
                          }}
                          buttonStyle={{
                            backgroundColor: 'transparent',
                            border: '0px',
                            outline: 'none',
                          }}
                          value={value}
                          onChange={onChange}
                          // country={'in'}
                          country={currentShop?.store_details.store_country_code.toLocaleLowerCase()}
                          // onlyCountries={['in']}
                          countryCodeEditable={false}
                          // enableSearch
                        />
                      </>
                    )}
                  />
                </div>

                <div className='form-group mb-4'>
                  <label
                    htmlFor='tableNumber'
                    className='mb-3'
                  >
                    Table Number{' '}

                    <span
                      style={{
                        color: '#ccc',
                        fontSize: '16px',
                        fontWeight: '600',
                        lineHeight: '18px',
                        letterSpacing: '-0.08px',
                      }}
                    >
                      (optional)
                    </span>
                  </label>

                  <input
                    type='text'
                    id='tableNumber'
                    className={`form-control  form-control-lg ${
                      errors.tableNumber ? 'is-invalid' : ''
                    }`}
                    {...register('tableNumber', { required: false, })}
                  />
                </div>
              </div>
            </form>

            <div className='process__pay__bottomfixbtn'>
              <button
                type='submit'
                form='hook-form'
              >
                Proceed to pay
              </button>
            </div>
          </>
        )}
      </div>

      {currentShop &&
        userInfoStripe &&
        storePaymentGateway === EStorePaymentGateway.STRIPE && (
          <StripeModal
            // stripe={stripe}
            showStripeModal={showStripeModal}
            setShowStripeModal={setShowStripeModal}
            setIsLoading={setIsLoading}
            store={currentShop}
            receipt={receipt}
            totalAmount={totalAmount}
            cart={cart}
            deliveryInstructions={deliveryInstructions}
            deliveryMode={deliveryMode}
            orderNote={location?.state?.orderNote}
            userInfo={userInfoStripe}
          />
        )}

      {currentShop &&
        storePaymentGateway === EStorePaymentGateway.STRIPE &&
        isStripePaymentFailed && (
          <PaymentFailedModal
            setIsStripePaymentFailed={setIsStripePaymentFailed}
          />
        )}
    </>
  );
};
