import { Reducer } from 'redux';

import { IUserState, EUserActionTypes, TUserActions } from './user.types';

const INITIAL_STATE: IUserState = {
  currentUser: null,
};

export const userReducer: Reducer<IUserState, TUserActions> = (state = INITIAL_STATE, action: TUserActions): IUserState => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (action.type) {
    case EUserActionTypes.SET_CURRENT_USER_ADDRESS:
      return {
        ...state,
        currentUser: action.payload,
      };

    default:
      return state;
  }
};