import React from 'react'

import { ICartItem, IModifier } from 'store/cart/cart.types';

interface Props {
  cartItems: ICartItem[];
  currencySymbol: string | null | undefined;
}

export const ItemSummary = ({ cartItems, currencySymbol, }: Props) => {
  const modifiersRow = (modifiers: IModifier[]) => {
    return modifiers.map((md, index) => {
      return (
        <tr key={`item__row__modifier - ${md.name} - ${index}`}>
          <td>
            <div className='placeOrder__groupedModifier'>
              <p className='modifierName'>{md.groupName}</p>

              <p>{md.name}</p>
            </div>
          </td>

          <td
            style={{
              textAlign: 'right',
            }}
          >
            {currencySymbol}

            {md.price * md.quantity}
          </td>
        </tr>
      );
    });
  };

  return (
    <div className='YourOrderInfo'>
      <table
        cellSpacing='0'
        cellPadding='0'
        style={{ width: '100%', }}
      >
        {cartItems.map((item, index) => {
          return (
            <React.Fragment key={`item__row - ${item.name} - ${index}`} >
              <tr
                className='item'
              >
                <td>
                  <div className='item_info'>
                    <p className='item_quantity'>{item.quantity}</p>

                    <div className='itemName'>
                      <p>{item.name}</p>
                    </div>
                  </div>
                </td>

                <td
                  style={{
                    textAlign: 'right',
                  }}
                >
                  {currencySymbol}

                  {item.price * (item.quantity ? item.quantity : 1)}
                </td>
              </tr>

              {item.modifiers.length > 0 &&
                modifiersRow(item.modifiers as IModifier[])}

              <tr className='bordered'>
                <td></td>

                <td></td>
              </tr>
            </React.Fragment>
          );
        })}
      </table>
    </div>
  );
};
