import React from 'react';

import Alert from 'react-bootstrap/Alert';
import './ErrorModal.css';

interface Props {
  errorMessage: string | React.ReactNode;
}

export const ErrorModal = ({ errorMessage, }: Props) => {

  return (
    <div className='ErrorModal-errorMessage'>

      <Alert variant='danger'>
        {React.isValidElement(errorMessage) ? (
          <>
            {errorMessage}
          </>
        ) : (
          <>
            <Alert.Heading>Oh snap! You got an error!</Alert.Heading>

            <p>{errorMessage}</p>
          </>
        )}
      </Alert>
    </div>
  );
};
