import getSymbolFromCurrency from 'currency-symbol-map'
import { Reducer } from 'redux';

import { IShopeState, EShopActionTypes, TShopActions } from './shop.types';

const INITIAL_STATE: IShopeState = {
  currentShop: null,
  errorMessage: null,
  currencySymbol: null,
};

export const shopReducer: Reducer<IShopeState, TShopActions> = (
  state = INITIAL_STATE,
  action: TShopActions
): IShopeState => {
  switch (action.type) {

    case EShopActionTypes.FETCH_SHOP_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
      };

    case EShopActionTypes.FETCH_SHOP_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        currentShop: action.payload,
        currencySymbol: getSymbolFromCurrency(action.payload.store_details.store_currency),
      };

    default:
      return state;
  }
};
