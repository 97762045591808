import { createSelector } from 'reselect';

import { IAddress, TAddressTag } from 'store/user/user.types';

import { RootState } from '..';

const selectUser = (state: RootState) => state.user;

export const selectCurrentUser = createSelector(
  [selectUser],
  (user) => user.currentUser
);

export const selectSelectedAddress = createSelector(
  [selectCurrentUser],
  (user) => {
    const address = user?.address

    if (address) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const selectedAddressTag = Object.keys(address).find(key => address[key].selected === true) as TAddressTag;

      if (selectedAddressTag)
        return address[selectedAddressTag]

    }

    return null
  }
);