import { useSelector } from 'react-redux';

import InfoIcon from 'assets/images/infoIcon.jpg';
import { DiscountTooltip, DeliveryFeeTooltip } from 'components';
import { EDeliveryMode } from 'store/cart/cart.types';
import { selectCurrentShop, selectDeliveryFeeTooltip } from 'store/shop/shop.selectors';

interface Props {
  deliveryMode: EDeliveryMode;
  deliveryFee: number;
  cartSubTotal: number;
  cartDiscount: number;
  cartTax: number;
  cartTotal: number;
  currencySymbol: string | null | undefined;
  minAmountDelivery?: number;
  showDiscountInfo?: boolean;
  shouldProcessToPay?: boolean;
}

export const TotalSummary = ({
  deliveryMode,
  deliveryFee,
  cartSubTotal,
  cartDiscount,
  cartTax,
  cartTotal,
  currencySymbol,
  minAmountDelivery = 0,
  showDiscountInfo = false,
  shouldProcessToPay = false,
}: Props) => {

  const deliveryFeeTooltip = useSelector(selectDeliveryFeeTooltip);

  return (
    <div
      className='SummaryWrap'
      style={{ marginTop: 24, }}
    >
      <div className='SummaryMain'>
        <ul>
          <li>
            <p className='title'>Subtotal</p>

            <p className='rate'>
              {currencySymbol}

              {cartSubTotal}
            </p>
          </li>

          <li>
            <div>
              <p className='title'>
                Discount {' '}

                {showDiscountInfo && (
                  <DiscountTooltip placement='right'>
                    <img
                      src={InfoIcon}
                      alt='info'
                      width={10}
                    />
                  </DiscountTooltip>
                )}
              </p>
            </div>

            <p className='rate discount'>
              - {currencySymbol}

              {cartDiscount}
            </p>
          </li>

          {deliveryMode === EDeliveryMode.DELIVERY && (
            <li>
              <div>
                <p className='title'>Delivery Fee {' '}

                  {minAmountDelivery > 0 && (
                    <>
                      <DeliveryFeeTooltip placement='right'>
                        <img
                          src={InfoIcon}
                          alt='info'
                          width={10}
                        />
                      </DeliveryFeeTooltip>

                    </>
                  )}

                  {shouldProcessToPay && (
                    <p
                      className='total__summary__blink_me'
                      style={{ color: 'red', }}
                    >

                      {deliveryFeeTooltip.msg1}

                      {' '}

                      <strong>{deliveryFeeTooltip.msg2}</strong>

                      {' '}

                      {deliveryFeeTooltip.msg3}

                      {' '}

                      <strong>Please add more items to the cart</strong>. </p>
                  )}

                </p>

              </div>

              <p className='rate'>
                {currencySymbol}

                {deliveryFee}
              </p>
            </li>
          )}

          <li>
            {/* <OverlayTrigger
                placement='right'
                delay={{
                  show: 250,
                  hide: 400,
                }}
                overlay={renderTaxTooltip}
              >
                <p className='title'>
                  Taxes <img
                    src={InfoIcon}
                    alt='info'
                    width={10}
                  />
                </p>
              </OverlayTrigger> */}

            <p className='title'>Taxes</p>

            <p className='rate'>
              {currencySymbol}

              {cartTax}
            </p>
          </li>

          <li className='total'>
            <p className='title'>Total</p>

            <p className='rate'>
              {currencySymbol}

              {cartTotal}
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};
