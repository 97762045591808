/**
 *? Interface is kind of type. You can easily convert interface into type.
 *? If you want, you can create a separate file for interface
 */

export enum ECartActionTypes {
  ADD_SIMPLE_ITEM = 'ADD_SIMPLE_ITEM',
  ADD_COMPLEX_ITEM = 'ADD_COMPLEX_ITEM',
  REMOVE_ITEM = 'REMOVE_ITEM',
  CLEAR_ITEM_FROM_CART = 'CLEAR_ITEM_FROM_CART',
  CLEAR_CART = 'CLEAR_CART',
  TOGGLE_DELIVERY_MODE = 'TOGGLE_DELIVERY_MODE',
  ADD_PAYLOAD_ITEMS = 'ADD_PAYLOAD_ITEMS',
}

export interface IModifier {
  groupId: string;
  groupName: string;
  id: string;
  name: string;
  price: number;
  quantity: number;
  modifiers: any[];
}

export interface ICartItem {
  id: string;
  name: string;
  quantity?: number;
  note: string;
  price: number;
  modifiers: string[] | IModifier[] | [];
}

export interface ICartState {
  cartItems: ICartItem[];
  deliveryMode: EDeliveryMode;
}

export enum EDeliveryMode {
  DELIVERY = 'RESTAURANT_DELIVERY',
  PICKUP = 'PICKUP',
  RESTAURANT_DELIVERY = 'RESTAURANT_DELIVERY',
}

interface IAddSimpleItemToCart {
  type: ECartActionTypes.ADD_SIMPLE_ITEM;
  payload: ICartItem;
}

interface IAddComplexItemToCart {
  type: ECartActionTypes.ADD_COMPLEX_ITEM;
  payload: ICartItem;
}
interface IRemoveItemFromCart {
  type: ECartActionTypes.REMOVE_ITEM;
  payload: ICartItem;
}

interface IClearCart {
  type: ECartActionTypes.CLEAR_CART;
}

interface IClearItemFromCart {
  type: ECartActionTypes.CLEAR_ITEM_FROM_CART;
  payload: ICartItem;
}

interface IToggleDeliveryMode {
  type: ECartActionTypes.TOGGLE_DELIVERY_MODE;
  payload: EDeliveryMode;
}

interface IAddPayloadItems {
  type: ECartActionTypes.ADD_PAYLOAD_ITEMS;
  payload: ICartItem[];
}

export type TCartActions =
  | IAddSimpleItemToCart
  | IAddComplexItemToCart
  | IRemoveItemFromCart
  | IClearCart
  | IClearItemFromCart
  | IToggleDeliveryMode
  | IAddPayloadItems;
