/* eslint-disable sonarjs/cognitive-complexity */
import axios, { AxiosError } from 'axios';
import { Dispatch } from 'redux';

import { TimeZoneError } from 'components';
import {
  calcTime,
  compareTime,
  getMenuTime,
  getShopMenu,
  getTodayStoreTime
} from 'utils/helper';
import { IParsedQueryString } from 'utils/parsedQueryString';

import { RootState } from '..';
import { getProductsList } from '../../utils/agent';
import { EShopActionTypes, TShopActions, IShope } from './shop.types';

export const setCurrentShop = (queryString: IParsedQueryString) => {
  return async (
    dispatch: Dispatch<TShopActions>,
    getCurrentState: RootState
  ) => {
    if (queryString.store_id) {
      try {
        const products = await getProductsList(queryString.store_id);
        const shopData = products.data as IShope;

        const localTimeWithZone = calcTime(
          shopData.store_details.store_timezone
        );

        if (!localTimeWithZone) {

          dispatch({
            type: EShopActionTypes.FETCH_SHOP_FAILURE,
            payload: 'Store time zone is not provided in store_details',
          });

          return;
        }

        const shopMenu = getShopMenu(shopData);

        if (shopMenu) {
          const storeTime = getTodayStoreTime(shopMenu);

          const isStoreOpened = compareTime(storeTime, localTimeWithZone);

          if (isStoreOpened) {
            dispatch({
              type: EShopActionTypes.FETCH_SHOP_SUCCESS,
              payload: shopData,
            });
          } else {
            dispatch({
              type: EShopActionTypes.FETCH_SHOP_FAILURE,
              // payload: 'Store is closed at the moment please try later.',
              payload: TimeZoneError(shopData, getMenuTime(storeTime)),
            });
          }
        }
        else {
          dispatch({
            type: EShopActionTypes.FETCH_SHOP_FAILURE,
            payload: 'Store Menu is missing',
          });
        }
      } catch (error) {
        const err = error as AxiosError;

        if (axios.isAxiosError(err) && err?.response) {
          // console.log(err?.response.data);
          // console.log(err?.response.status);
          // console.log(err.response.headers);
          const errorMsg =
            err.response.data.code === 404
              ? 'Sorry restaurant with this brand id does not exist'
              : 'Something went wrong. Please try to reload the page';

          dispatch({
            type: EShopActionTypes.FETCH_SHOP_FAILURE,
            payload: errorMsg,
          });
        }
      }
    } else {
      dispatch({
        type: EShopActionTypes.FETCH_SHOP_FAILURE,
        payload: 'Please Provide a store id before continuing.',
      });
    }
  };
};
