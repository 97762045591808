import { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Modal, ItemSummary, TotalSummary } from 'components';
import { toggleDeliveryMode } from 'store/cart/cart.action.creator';
import { EDeliveryMode } from 'store/cart/cart.types';
import { selectShopCurrencySymbol } from 'store/shop/shop.selectors';
import { IServerOrderResponse } from 'utils/types';

export const PostOrder = () => {
  const navigate = useNavigate();
  const { search, state, } = useLocation();
  const dispatch = useDispatch();

  const { serverResponse, }: { serverResponse: IServerOrderResponse, } = state;

  const currencySymbol = useSelector(selectShopCurrencySymbol);
  const { data: { order, }, message, error_message: errorMessage = null, } = serverResponse;
  const {
    items: cartItems, fulfillmentInfo: { fulfillmentMode: deliveryMode, }, orderTotal: { deliveryFee, discount: cartDiscount, subtotal: cartSubTotal, tax: cartTax, total: cartTotal, },
  } = order;

  const isCartEmpty = cartItems.length === 0;

  useEffect(() => {
    if (isCartEmpty) navigate(`/${search}`);
  }, [isCartEmpty, navigate, search]);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (message || errorMessage)
      setShowModal(true);

    return () => { };
  }, [errorMessage, message]);

  useEffect(() => {
    if (!serverResponse) return;

    const fulfillmentMode = serverResponse.data.order.fulfillmentInfo.fulfillmentMode;
    dispatch(toggleDeliveryMode(fulfillmentMode as EDeliveryMode));

  }, [dispatch, serverResponse]);

  return (
    <>
      <Modal
        setShowModal={setShowModal}
        showModal={showModal}
        message={message}
        errorMessage={errorMessage}
        currencySymbol={currencySymbol}
      />

      <div className='YourOrderWrapper'>
        <div className='YourOrderHeading'>
          <h5>Your Order Summary</h5>
        </div>

        <ItemSummary
          cartItems={cartItems}
          currencySymbol={currencySymbol}
        />
      </div>

      <TotalSummary
        deliveryMode={deliveryMode as EDeliveryMode}
        deliveryFee={deliveryFee}
        cartSubTotal={cartSubTotal}
        cartDiscount={cartDiscount}
        cartTax={cartTax}
        cartTotal={cartTotal}
        currencySymbol={currencySymbol}
      />

      <div className='item-add__bottom__margin' />
    </>
  );
};
