import React from 'react';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useSelector } from 'react-redux';

import { selectDiscountTooltip } from 'store/shop/shop.selectors';

interface Props {
  children: React.ReactElement;

  placement?: 'bottom' | 'right';
}

export const DiscountTooltip = ({ children, placement = 'bottom' }: Props) => {
  const discountTooltip = useSelector(selectDiscountTooltip);
  const renderDiscountTooltip = (props: any) => {
    return (
      <Tooltip id='button-tooltip-discount' {...props}>
        {discountTooltip}
      </Tooltip>
    );
  };

  return (
    <OverlayTrigger
      placement={placement}
      delay={{
        show: 250,
        hide: 400,
      }}
      overlay={renderDiscountTooltip}
    >
      {children}
    </OverlayTrigger>
  );
};
