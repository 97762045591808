import React from 'react';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useSelector } from 'react-redux';

import { selectDeliveryFeeTooltip } from 'store/shop/shop.selectors';

interface Props {
  children: React.ReactElement;

  placement?: 'bottom' | 'right';
}

export const DeliveryFeeTooltip = ({
  children,
  placement = 'bottom',
}: Props) => {
  const deliveryFeeTooltip = useSelector(selectDeliveryFeeTooltip);

  const renderDiscountTooltip = (props: any) => {
    return (
      <Tooltip
        id='button-tooltip-delivery'
        {...props}
      >
        {deliveryFeeTooltip.msg1}

        {' '}

        <strong>{deliveryFeeTooltip.msg2}</strong>

        {' '}

        {deliveryFeeTooltip.msg3}
      </Tooltip>
    );
  };

  return (
    <OverlayTrigger
      placement={placement}
      // defaultShow={true} // will work if no delay prop
      delay={{
        show: 250,
        hide: 400,
      }}
      overlay={renderDiscountTooltip}
    >
      {children}
    </OverlayTrigger>
  );
};
