/**
 *? Interface is kind of type. You can easily convert interface into type.
 *? If you want, you can create a separate file for interface
 */

import { ICartItem } from 'store/cart/cart.types';

// ? ------------------------------ response ----------------------------------------
export interface INewOrderIdRazorpayResponse {
  message: {
    amount: number;
    amount_due: number;
    amount_paid: number;
    attempts: number;
    created_at: number;
    currency: string;
    entity: string;
    id: string;
    notes: any[];
    offer_id: null;
    receipt: string;
    status: string;
  };
}

export interface INewOrderIdStripeResponse {
  clientSecret: string;
  payment_gateway_order_id: string;
  payment_gateway_payment_id: string;
  receipt: string;
  status: string;
}
export interface IRazorpayResponse {
  razorpay_payment_id: string;
  razorpay_order_id: string;
  razorpay_signature: string;
  org_logo: string;
  org_name: string;
  checkout_logo: string;
  custom_branding: boolean;
}

export interface IServerOrderResponse {
  data: IData;
  message?: {
    message: string;
    order_id: string;
    order_total: string;
  };
  error_message?: string;
}

// ? ------------------------------ payload ----------------------------------------

export interface INewOrderIdPayload {
  store_payment_gateway: 'Razorpay' | 'Stripe';
  amount: number;
  currency: string;
  receipt: string;
  account_id: string;
}

export interface IConfirmOrderPayload {
  data: IData;
}

export interface IData {
  metadata: Metadata;
  order: Order;
}

export interface Metadata {
  store_id: string;
  order_type: string;
  store_payment_gateway: EStorePaymentGateway;
  payment_gateway_order_id: string;
  payment_gateway_payment_id: string;
  payment_gateway_signature: string;
  payment_gateway_receipt_number: string;
  payment_gateway_account_id: string;
}

export enum EStorePaymentGateway {
  RAZORPAY = 'Razorpay',
  STRIPE = 'Stripe',
}
export interface Order {
  // items: Item[];
  items: ICartItem[];
  orderedAt: string;
  currencyCode: string;
  customer: Customer;
  status: string;
  deliveryInfo: DeliveryInfo;
  customerNote: string;
  orderTotal: OrderTotal;
  customerPayments: CustomerPayment[];
  fulfillmentInfo: FulfillmentInfo;
}

export interface Customer {
  name: string;
  phone: string;
  email: string;
}

export interface CustomerPayment {
  value: number;
  processingStatus: string;
  paymentMethod: string;
}

export interface DeliveryInfo {
  destination: Destination;
  lastKnownLocation: Location;
  note: string;
}

export interface Destination {
  postalCode: string;
  city: string;
  state: string;
  countryCode: string;
  addressLines: string[];
  location: Location;
}

export interface Location {
  latitude: string;
  longitude: string;
}

export interface FulfillmentInfo {
  fulfillmentMode: string;
}

// export interface Item {
//   id: string;
//   name: string;
//   quantity: number;
//   note: string;
//   price: number;
//   modifiers?: Modifier[];
// }

export interface Modifier {
  groupId: string;
  groupName: string;
  id: string;
  name: string;
  price: number;
  quantity: number;
  modifiers: any[];
}

export interface OrderTotal {
  subtotal: number;
  discount: number;
  couponCode: string;
  deliveryFee: number;
  tax: number;
  tip: number;
  total: number;
}
