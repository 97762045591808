import { AddressForm } from 'components';

interface Props {

}

export const DeliveryAddress = (props: Props) => {
  return (
    <div>
      <AddressForm />
    </div>
  )
}

