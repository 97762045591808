import React, { useState, useEffect, useRef } from 'react';

import { Button, Modal } from 'react-bootstrap';

interface Props {
  setIsStripePaymentFailed: (isStripePaymentFailed: boolean) => void;
}
export const PaymentFailedModal = ({ setIsStripePaymentFailed, }: Props) => {
  return (
    <Modal
      show={true}
      centered
      // onHide={() => {
      //   setShowStripeModal(false);
      //   setIsLoadingParent(false);
      //   removeStripePayloadFromLocalStorage();
      // }}
    >
      <Modal.Body>
        <p>Payment failed please try again</p>

        <div className='backBtn text-right'>
          <Button
            variant='secondary'
            className='btn pull-right'
            style={{
              float: 'right',
            }}
            onClick={() => setIsStripePaymentFailed(false)}
          >
            Ok
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
