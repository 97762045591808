/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { ItemSummary, TotalSummary } from 'components';
import { useCartSummary } from 'hooks/useCartSummary';
import './PlaceOrder.css';
import { EDeliveryMode } from 'store/cart/cart.types';
import { selectCurrentShop } from 'store/shop/shop.selectors';
import { removeStripePayloadFromLocalStorage } from 'utils/helper';

export const PlaceOrder = () => {
  const navigate = useNavigate();
  const { search, } = useLocation();
  const [orderNote, setOrderNote] = useState('');

  const {
    cartItems,
    deliveryMode,
    deliveryFee,
    cartSubTotal,
    cartDiscount,
    cartTax,
    cartTotal,
    currencySymbol,
  } = useCartSummary();

  const isCartEmpty = cartItems.length === 0;

  useEffect(() => {
    if (isCartEmpty) navigate(`/${search}`);
  }, [isCartEmpty, navigate, search]);

  useEffect(() => {
    removeStripePayloadFromLocalStorage();

  }, []);

  const currentShop = useSelector(selectCurrentShop);
  let shouldProcessToPay = false;

  // -if store_min_amt_dlvry is 0 or missing or empty string then don't apply the logic. Apply the logic only if store_min_amt_dlvry > 0

  const minAmountDelivery = Number(currentShop?.store_details?.store_min_amt_dlvry);

  if (minAmountDelivery > 0) {
    if (cartSubTotal >= minAmountDelivery)
      shouldProcessToPay = false;
    else shouldProcessToPay = true;
  }

  const fixedDiscount = currentShop?.store_details.store_discount_amount;
  const percentagedDiscount = currentShop?.store_details.store_discount_percent;

  const showDiscountInfo = (Number(fixedDiscount)) > 0 || !!percentagedDiscount;

  return (
    <>
      <div className='YourOrderWrapper'>
        <div className='YourOrderHeading'>
          <h5>Your Order Summary</h5>

          {/* Add button from ProcessToPay instead of anchor tag */}

          <a onClick={() => navigate(`/${search}`)}>Add items +</a>
        </div>

        <ItemSummary
          cartItems={cartItems}
          currencySymbol={currencySymbol}
        />
      </div>

      <TotalSummary
        deliveryMode={deliveryMode}
        deliveryFee={deliveryFee}
        cartSubTotal={cartSubTotal}
        cartDiscount={cartDiscount}
        cartTax={cartTax}
        cartTotal={cartTotal}
        currencySymbol={currencySymbol}
        minAmountDelivery={minAmountDelivery || 0}
        showDiscountInfo={showDiscountInfo}
        shouldProcessToPay={shouldProcessToPay}
      />

      <div className='SummaryWrap'>

        <h5>Order Notes</h5>

        <textarea
          onChange={({ target, }) => setOrderNote(target.value)}
          placeholder='Additional notes...'
          rows={4}
          className='order__note-textArea'
        ></textarea>
      </div>

      <div className='item-add__bottom__margin' />

      <div className='bottomfixbtn'>
        <button
          onClick={() => {
            navigate(`/processToPay${search}`, {
              state: {
                orderNote,
                shouldProcessToPay,
              },
            });
          }}
          disabled={shouldProcessToPay && deliveryMode === EDeliveryMode.DELIVERY}
        >
          place order
        </button>
      </div>
    </>
  );
};
