/* eslint-disable sonarjs/cognitive-complexity */
import React, { useContext, useEffect, useState } from 'react';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  useForm,
  useWatch,
  Control,
  Controller,
  SubmitHandler
} from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { addComplexItemToCart } from 'store/cart/cart.action.creator';
import { ICartItem, IModifier } from 'store/cart/cart.types';
import {
  selectShopCurrencySymbol,
  selectCurrentShop
} from 'store/shop/shop.selectors';
import { IShopItem } from 'store/shop/shop.types';
import './Item.css';

interface Props { }
interface IFormInputs {
  [key: string]: string;
}

// type IFormInputs = IModifier[];

export const Item = (props: Props) => {
  const navigate = useNavigate();
  const { search, } = useLocation();
  const { itemID, } = useParams();

  const currencySymbol = useSelector(selectShopCurrencySymbol);
  const currentShop = useSelector(selectCurrentShop);
  const dispatch = useDispatch();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, },
    setValue,
  } = useForm<IFormInputs>({
    mode: 'onBlur',
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!itemID) {
    return <h1>Please provide a item id</h1>;
  }

  const items = currentShop?.store_menu.items;
  const selectedItem = items?.[itemID] as IShopItem;

  const modifierGroups = currentShop?.store_menu.modifierGroups;

  const onSubmit = handleSubmit((data) => {
    const modifiers = Object.values(data) as unknown as IModifier[];
    const flattenModifier = modifiers.flat(Infinity).filter((n) => n);

    const cartComplexItemToAdd: ICartItem = {
      id: selectedItem.id,
      name: selectedItem.name,
      quantity: 1,
      note: '',
      price: selectedItem.price.amount,
      modifiers: flattenModifier,
    };

    dispatch(addComplexItemToCart(cartComplexItemToAdd));
    navigate(`/${search}#${selectedItem.id}`);
  });

  return (
    <>
      {items && selectedItem && (
        <form onSubmit={onSubmit}>
          <div className='CategoryWrapper'>
            <div className='Categoryheading'>
              <h5>{selectedItem.name}</h5>

              <FontAwesomeIcon
                icon={faTimes}
                onClick={() => window.history.back()}
              />
            </div>

            <div className='Categoryorderinfo'>
              {selectedItem.modifierGroupIds.length ? (
                <div>
                  {selectedItem.modifierGroupIds.map((modifierId, index) => {
                    const maximumSelections =
                      modifierGroups?.[modifierId].maximumSelections;

                    const groupModifierName =
                      modifierGroups?.[modifierId].name ?? '';
                    const groupModifierId = modifierGroups?.[modifierId].id;

                    if (maximumSelections && maximumSelections > 1) {
                      return (
                        <div className='ChooseSize SelectCrust Addon'>
                          <div className='ChooseSizeHeading'>
                            <h6>{groupModifierName} </h6>
                          </div>

                          <div className='ChooseSizeForm'>
                            <ul>
                              {modifierGroups?.[modifierId].itemIds.map(
                                (itemId, index) => {
                                  const modifierItem = items[itemId];

                                  return (
                                    <li
                                      key={`checkbox--${index}-${groupModifierName}`}
                                    >
                                      <label className='radiocustom'>
                                        <div className='radiocustomtext'>
                                          <p>{modifierItem.name}</p>

                                          <span>
                                            {currencySymbol}

                                            {modifierItem.price.amount}
                                          </span>
                                        </div>

                                        <Controller
                                          control={control}
                                          name={groupModifierName}
                                          rules={{ required: false, }}
                                          render={({
                                            field: {
                                              onChange,
                                              onBlur,
                                              value = [],
                                              name,
                                              ref,
                                            },
                                          }) => (
                                            <input
                                              type='checkbox'
                                              name={groupModifierName}
                                              // onChange={onChange}
                                              onChange={(event): void => {
                                                const checked =
                                                  event.target.checked;

                                                const checkboxValue =
                                                  value as IModifier[];

                                                if (checked) {
                                                  onChange([
                                                    ...checkboxValue,
                                                    {
                                                      groupId: groupModifierId,
                                                      groupName:
                                                        groupModifierName,
                                                      id: modifierItem.id,
                                                      name: modifierItem.name,
                                                      price:
                                                        modifierItem.price
                                                          .amount,
                                                      quantity: 1,
                                                      modifiers: [],
                                                    }
                                                  ]);
                                                } else {
                                                  onChange(
                                                    checkboxValue.filter(
                                                      (value: IModifier) =>
                                                        value.id !==
                                                        event.target.value
                                                    )
                                                  );
                                                }
                                              }}
                                              value={modifierItem.id}
                                              ref={ref}
                                            // checked={!!value}
                                            />
                                          )}
                                        />

                                        {/* <input
                                            type='checkbox'
                                            // name={
                                            //   store_menu.modifierGroups[modifier]
                                            //     .name
                                            // }
                                            name={
                                              currentShop?.store_menu
                                                .modifierGroups[modifierId].id
                                            }
                                            value={itemId}
                                          // onChange={handleCheckboxChange}
                                          /> */}

                                        <span className='checkmarkcustom'></span>
                                      </label>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </div>
                        </div>
                      );
                    } else if (maximumSelections === 1) {
                      return (
                        <div
                          className='ChooseSize'
                          key={`radio--${index}-${modifierGroups?.[modifierId].name}`}
                          style={
                            errors[groupModifierName]
                              ? {
                                // outline: '1px solid red',
                                boxShadow: '0 0 20px indianred',
                                backgroundColor: 'transparent',
                                // padding: 0,
                              }
                              : {}
                          }
                        >
                          <div className='ChooseSizeHeading'>
                            <h6>{groupModifierName}</h6>

                            <button className='requiredBtn'>Required</button>
                          </div>

                          <div className='ChooseSizeForm'>
                            <ul>
                              {modifierGroups?.[modifierId].itemIds.map(
                                (itemId, index, array) => {
                                  const modifierItem = items[itemId];

                                  const modifierDataAsPayload = {
                                    groupId: groupModifierId,
                                    groupName: groupModifierName,
                                    id: modifierItem.id,
                                    name: modifierItem.name,
                                    price: modifierItem.price.amount,
                                    quantity: 1,
                                    modifiers: [],
                                  };

                                  const isModifierOne = array.length === 1;

                                  if (isModifierOne) {
                                    setValue(
                                      groupModifierName,
                                      modifierDataAsPayload as unknown as string
                                    );
                                  }

                                  return (
                                    <li
                                      key={`radiobutton--${index}-${groupModifierName}`}
                                    >
                                      <label className='radiocustom'>
                                        <div className='radiocustomtext'>
                                          <p>{modifierItem.name}</p>

                                          <span>
                                            {currencySymbol}

                                            {modifierItem.price.amount}
                                          </span>
                                        </div>

                                        <Controller
                                          control={control}
                                          name={groupModifierName}
                                          rules={{ required: true, }}
                                          render={({
                                            field: {
                                              onChange,
                                              onBlur,
                                              value,
                                              ref,
                                            },
                                          }) => (
                                            <input
                                              type='radio'
                                              name={groupModifierName}
                                              // value={value}
                                              // onChange={onChange}
                                              checked={
                                                isModifierOne ? true : undefined
                                              }
                                              // onChange={() =>
                                              //   onChange({
                                              //     groupId:
                                              //       modifierGroups?.[modifierId]
                                              //         .id,
                                              //     groupName:
                                              //       modifierGroups?.[modifierId]
                                              //         .name,
                                              //     id: modifierItem.id,
                                              //     name: modifierItem.name,
                                              //     price:
                                              //       modifierItem.price.amount,
                                              //     quantity: 1,
                                              //     modifiers: [],
                                              //   })
                                              // }
                                              onChange={() => {
                                                onChange(modifierDataAsPayload);
                                              }}
                                            />
                                          )}
                                        />

                                        {/* <input
                                            type='radio'
                                            name={
                                              currentShop?.store_menu
                                                .modifierGroups[modifierId].id
                                            }
                                            value={itemId}
                                          // onChange={handleRadioChange}
                                          /> */}

                                        <span className='checkmarkcustom'></span>
                                      </label>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              ) : (
                ''
              )}

              <div className='item-add__bottom__margin' />

              {/* <textarea
            onChange={({ target }) => setNote(target.value)}
            placeholder='Additional notes...'
            rows='4'
            className='item-textArea'></textarea> */}
            </div>
          </div>

          <div className='bottomfixbtn__confirmItem'>
            {/* <button onClick={onPlaceOrder}>Add to cart</button> */}

            <button type='submit'>Add to cart</button>
          </div>
        </form>
      )}
    </>
  );
};
