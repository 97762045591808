import axios from 'axios';

import { config } from 'config';
import { ICartItem, EDeliveryMode } from 'store/cart/cart.types';
import { IShope } from 'store/shop/shop.types';
import { parsedQueryString } from 'utils/parsedQueryString';
import { INewOrderIdPayload, EStorePaymentGateway, IConfirmOrderPayload } from 'utils/types';

const baseUrl = process.env.REACT_APP_BACKEND_URL;

export const getApiUrl = (_url: string) => baseUrl + _url;

async function sendRequest(
  url: string,
  method = 'GET' as const,
  data = null,
  headers = {}
) {
  return axios({
    url,
    method,
    data,
    headers,
    timeout: 5000,
  });
}

export const getProductsList = async (brandId: string) => {
  const url = getApiUrl(`/_fabric/_system/_api/document/store-data/${brandId}`);

  return sendRequest(url, 'GET', null, {
    Authorization: process.env.REACT_APP_BACKEND_AUTH_TOKEN,
  });
};

export const getNewOrderId = async (store: IShope, totalAmount: number, receipt: string) => {

  const payload: INewOrderIdPayload = {
    store_payment_gateway: store?.store_details.store_payment_gateway as EStorePaymentGateway,
    amount: totalAmount,
    currency: store?.store_details.store_currency,
    receipt: receipt,
    account_id: store?.store_details.store_pg_merchant_id,
  };

  const data = JSON.stringify(payload);
  const response = await fetch(`${config?.baseOrderUrl}/new_order_id`, {
    method: 'POST',
    body: data,
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': config?.xApiKey,
    },
  });

  return response.json();
};

export const postConfirmOrder = async (confirmOrderPayload: IConfirmOrderPayload) => {

  const myHeaders = new Headers();
  myHeaders.append('x-api-key', config.xApiKey);
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(confirmOrderPayload),
    // redirect: 'follow',
  };

  return fetch(`${config.baseOrderUrl}/confirm_order`, requestOptions);

  // 
  //   .then((response) => response.text())
  //   .then((result) => {
  //     setIsLoading(false);

  //     navigate(`/postOrder${location.search}`, {
  //       state: { serverResponse: JSON.parse(result), },
  //     });
  //   })
  //   .catch((error) => {
  //     //
  //   });
};